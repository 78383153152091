import { Card, Typography } from '@mui/material';
import React from 'react';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type ModalWrapperProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  modalTitle: string;
  modalDescription?: string;
};

export default function ModalWrapper({
  children,
  open,
  onClose,
  modalTitle,
  modalDescription,
}: ModalWrapperProps) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Card sx={{ px: 3, py: 2 }}>
        <Typography variant="subtitle1">
          {String(translate(modalTitle))}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {String(translate(modalDescription))}
        </Typography>
        {children}
      </Card>
    </DialogAnimate>
  );
}
