import { useForm } from 'react-hook-form';
import { MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import { useRequestConsultation } from '#/api/partnerQueries';

type Props = {
  advisorsList: string[];
  advisorType: string;
  closeModal?: () => void;
};

const legalConsultingList = [
  {
    value: 'livingWill',
  },
  {
    value: 'powerOfAttorney',
  },
  {
    value: 'lastWill',
  },
  {
    value: 'marriageContract',
  },
];

const financialConsultingList = [
  {
    value: 'financialPlanning',
  },
  {
    value: 'pensionPlanning',
  },
  {
    value: 'taxPlanning',
  },
  {
    value: 'realEstate',
  },
  {
    value: 'successionPlanning',
  },
];

export default function AdvisorConsulationForm({
  advisorsList,
  advisorType,
  closeModal,
}: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const { mutateAsync: requestConsultation } = useRequestConsultation();

  const defaultValues = useMemo(
    () => ({
      topic: '',
      advisor_ids: advisorsList ?? [],
      message_body: '',
    }),
    [advisorsList]
  );
  const methods = useForm({
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    try {
      await requestConsultation(data).then(() => {
        closeModal?.();
        toast.success(
          String(translate('toast_notifications.success.consulationRequest'))
        );
        navigate('/dashboard/home');
      });
    } catch (error) {
      console.error(error);
      toast.error(
        String(translate('toast_notifications.error.consulationRequest'))
      );
    }
  };

  const getTopicLabel = (topic: string, advisorType: string) =>
    String(translate(`global.${advisorType}ConsultingTopics.${topic}`));

  const listByType =
    advisorType === 'legal' ? legalConsultingList : financialConsultingList;

  useEffect(() => {
    if (advisorsList) reset(defaultValues);
  }, [advisorsList]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2} sx={{ marginTop: 2 }}>
        <RHFSelect
          name="topic"
          label={String(translate('global.formLabels.topics'))}
        >
          {listByType.map((topic) => (
            <MenuItem
              key={topic.value}
              value={getTopicLabel(topic.value, advisorType)}
            >
              {getTopicLabel(topic.value, advisorType)}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFTextField
          multiline
          rows={5}
          name="message_body"
          label={String(translate('global.formLabels.message'))}
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <LoadingButton type="submit" variant="contained">
            <Typography>
              {String(translate('global.complexFormModal.requestConsultation'))}
            </Typography>
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
