import React, { useCallback, useState } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import Scrollbar from '#/components/shared/Scrollbar';
import { useGetEndClientAdvisorsList } from '#/api/partnerQueries';
import useTable from '#/hooks/useTable';
import cityData from '#/components/pages/Admin/consulting-legal-table/AddConsultant/cityData';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import LegalTableRow from '#/components/pages/Admin/consulting-legal-table/legal-table-row';
import TableEmptyRows from '#/components/shared/table/table-empty-rows';
import { emptyRows } from '#/components/shared/table/utils';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import useDebounce from '#/hooks/useDebounce';
import sortingModel from '#/components/pages/Admin/consulting-legal-table/sortingModel';
import ActionButtons from '#/components/shared/advisor-helpers/action-buttons';
import AdvisorConsulationForm from '#/components/shared/advisor-helpers/partner/advisor-consulation-form';

const defaultFilters = {
  advisor_name: '',
  location: [],
  search: '',
};

const TABLE_HEAD = [
  { id: '', width: 88 },
  { id: 'name', label: 'adminDashboard.consultantTable.tableHeads.name' },
  {
    id: 'location',
    label: 'adminDashboard.consultantTable.tableHeads.location',
    width: 250,
  },
];

const locations = cityData.map((city) => city.label);

type AdvisorsListProps = {
  advisorType: string;
  changeAnswer?: () => void;
  isComplexForm?: boolean;
  closeModal?: () => void;
};

export default function AdvisorsListView({
  advisorType,
  changeAnswer,
  isComplexForm,
  closeModal,
}: AdvisorsListProps) {
  const [showRequestProcess, setShowRequestProcess] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const debouncedSearch = useDebounce(filters.search, 500);
  const table = useTable();

  const hasFilters = filters?.location.length > 0 || debouncedSearch;

  const handleSorting = () => {
    const requestedField = sortingModel.find(
      (element) => element.field === table.orderBy
    );
    return requestedField?.sort_by[table.order];
  };

  const { data: advisors } = useGetEndClientAdvisorsList(
    hasFilters ? 1 : table.page + 1,
    advisorType,
    true,
    filters?.location.length > 0
      ? // @ts-ignore
        filters?.location.map((element) => element.toLowerCase()).join(',')
      : undefined,
    debouncedSearch || undefined,
    table.order ? handleSorting() : undefined
  );

  const canReset = !isEqual(defaultFilters, filters);

  const notFound =
    (!advisors?.results?.length && canReset) || !advisors?.results?.length;

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const { translate } = useLocales();

  const handleRequestProcess = () => {
    setShowRequestProcess(true);
  };

  return showRequestProcess ? (
    <AdvisorConsulationForm
      advisorsList={table.selected}
      closeModal={closeModal}
      advisorType={advisorType}
    />
  ) : (
    <>
      <TableToolbar
        filters={filters}
        onFilters={handleFilters}
        locationsOptions={locations}
      />

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar
          styledRootScrollbarSx={{
            height: advisors?.count === 0 ? '450px' : '300px',
            overflowY: 'scroll',
          }}
        >
          <Table size="small">
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              onSort={table.onSort}
            />
            <TableBody>
              {advisors?.results?.map((row) => (
                <LegalTableRow
                  key={row.id}
                  row={row}
                  selected={table.selected.includes(String(row.id))}
                  onSelectRow={() => table.onSelectRow(String(row.id))}
                  fromDefaultList
                  isPublicView
                />
              ))}
              <TableEmptyRows
                height={56}
                emptyRows={emptyRows(
                  table.page,
                  table.rowsPerPage,
                  advisors?.results?.length || 0
                )}
              />
              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      {!showRequestProcess && (
        <ActionButtons
          isComplexForm={isComplexForm}
          changeAnswer={changeAnswer}
        >
          <Button
            variant="contained"
            disabled={!table.selected.length}
            onClick={handleRequestProcess}
          >
            <Typography>
              {String(translate('global.complexFormModal.continueLabel'))}
            </Typography>
          </Button>
        </ActionButtons>
      )}
    </>
  );
}

type Props = {
  filters: {
    advisor_name: string;
    location: string[];
    search: string;
  };
  onFilters: (name: string, value: string | string[]) => void;
  locationsOptions: string[];
};

const TableToolbar = ({ filters, onFilters, locationsOptions }: Props) => {
  const { translate } = useLocales();

  const handleFilterSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search', event.target.value);
    },
    [onFilters]
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        py: 2.5,
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        <Autocomplete
          size="small"
          multiple
          id="location"
          value={filters.location}
          onChange={(_event, newValue) => {
            onFilters('location', newValue);
          }}
          options={locationsOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={String(translate('global.formLabels.location'))}
            />
          )}
        />
      </FormControl>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flexGrow={1}
        sx={{ width: 1 }}
      >
        <TextField
          size="small"
          fullWidth
          value={filters.search}
          onChange={handleFilterSearch}
          placeholder={String(translate('global.formLabels.search'))}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};
